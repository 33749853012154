<template>
  <div>
    <v-snackbar
      v-model="showAppointmentEnded"
      color="primary"
      elevation="24"
      class="pa-0"
      :timeout="-1"
      top
      centered
    >
      <div v-if="finishedAppointment">
        <div class="d-flex justify-space-between">
          <div>
            <h3>Consulta finalizada</h3>
            <div class="subtitle-2">
              {{
                getDiffBetweenTimes(
                  finishedAppointment.start,
                  finishedAppointment.end
                )
              }}
            </div>
          </div>
          <v-btn small icon @click="showAppointmentEnded = false"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </div>
        <v-btn
          @click="handleClickCreateNextAppointment"
          small
          depressed
          block
          color="white"
          class="primary--text my-5"
          title="Agendar próximo"
        >
          Agendar próxima consulta
        </v-btn>
        <AutoLinearProgressBar
          ref="linearProgressBarRef"
          @end="showAppointmentEnded = false"
          :time="6000"
        />
      </div>
    </v-snackbar>

    <v-dialog
      v-model="showFormNextAppointment"
      @keydown.esc="showFormNextAppointment = false"
      persistent
      width="900px"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon
            @click="showFormNextAppointment = false"
            style="cursor: pointer"
          >
            mdi-close
          </v-icon>
        </div>
        <NextAppointmentDateForm
          ref="nextAppointmentDateFormRef"
          @created="handleNextAppointmentCreated"
        />
      </v-card>
    </v-dialog>

    <v-slide-x-reverse-transition mode="out-in">
      <ChangeAttendingStatus
        v-if="!attending"
        ref="attendingComponent"
        :appointment="appointmentInProgress"
        :showStart="startAppointmentBtn"
        :clickStart="clickStart"
        @start-appointment="startAttending(todayAppointments[0])"
      />
      <v-card
        v-else-if="attending"
        flat
        dark
        class="pa-3 text-left mt-6 mt-md-0"
        color="primary"
      >
        <div class="justify-space-between align-end">
          <div class="mr-10">
            <div class="caption">Atendiendo a:</div>
            <Patient
              :patient="appointmentInProgress.patient"
              :clickFunc="goToPatientPage"
            >
              <h3 v-if="appointmentInProgress.code">
                {{ appointmentInProgress.patient.firstName }}
                {{ appointmentInProgress.patient.lastName }}
              </h3>
            </Patient>
          </div>
          <div class="d-md-flex mt-3">
            <NextTurn
              ref="nextTurn"
              class="mb-5 mb-md-0 mr-2"
              :updateAppointmentList="updateAppointmentList"
              @end="appointmentEnded"
            />

            <ChangeAttendingStatus
              ref="attendingComponent"
              :appointment="attending"
              class="mr-2 mb-5 mb-md-0"
            />

            <v-menu bottom offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!hideDotsBtn"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-3"
                  color="white"
                  text
                  small
                  icon
                >
                  <v-icon size="20px">mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="showPatientBtn"
                  :to="'/paciente/' + appointmentInProgress.patient.id"
                  color="secondary"
                >
                  <v-list-item-content class="font-weight-medium">
                    <div class="d-flex align-center">
                      <v-icon size="18px" color="secondary" class="mr-3"
                        >mdi-clipboard-text-outline</v-icon
                      >
                      <div class="subtitle-2">Historial</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="
                    appointmentInProgress && !appointmentInProgress.invoices[0]
                  "
                  @click="handleClickCobrar"
                  color="primary"
                >
                  <v-list-item-content class="font-weight-medium">
                    <div class="d-flex align-center">
                      <v-icon size="18px" color="secondary" class="mr-3">mdi-cash </v-icon>
                      <div class="subtitle-2">Facturar</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NextTurn from "./NextTurn.vue";
import Patient from "../Patient/Patient.vue";
import ChangeAttendingStatus from "./ChangeAttendingStatus.vue";
import AutoLinearProgressBar from "../AutoLinearProgressBar.vue";
import NextAppointmentDateForm from "./NextAppointmentDateForm.vue";
import { diffBetweenTimes } from "../../helpers/common";

export default {
  name: "AppointmentInProgress",
  props: [
    "updateAppointmentList",
    "showStartBtn",
    "showPatientBtn",
    "showDotsBtn",
    "clickStart",
    "hideDotsBtn",
  ],
  components: {
    NextTurn,
    Patient,
    ChangeAttendingStatus,
    AutoLinearProgressBar,
    NextAppointmentDateForm,
  },
  data() {
    return {
      finishedAppointment: null,
      hideDialogTimer: 0,
      intervalHideDialog: null,
      showFormNextAppointment: false,
      showAppointmentEnded: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "attending",
      "doctorForms",
      "todayAppointments",
      "currentLocation",
    ]),
    appointmentInProgress() {
      return this.attending;
    },
    startAppointmentBtn() {
      return this.showStartBtn && !this.attending;
    },
  },
  methods: {
    goToPatientPage() {
      if (this.appointmentInProgress?.patient) {
        this.$router.push(
          "/paciente/" + this.appointmentInProgress?.patient?.id
        );
      }
    },
    appointmentEnded(finishedAppointment) {
      this.$refs.attendingComponent.changeAttending(null);
      this.finishedAppointment = finishedAppointment;

      this.showAppointmentEnded = true;
      this.$nextTick(() => {
        this.$refs.linearProgressBarRef.start();
      });

      this.$emit("appointment-end", finishedAppointment);
    },
    getDiffBetweenTimes(time1, time2) {
      return diffBetweenTimes(time1, time2);
    },
    handleNextAppointmentCreated(appointment) {
      this.$emit("appointment-created", appointment);
      this.showFormNextAppointment = false;
    },
    handleClickCreateNextAppointment() {
      this.showAppointmentEnded = false;
      this.showFormNextAppointment = true;
      this.$nextTick(() => {
        this.$refs.nextAppointmentDateFormRef.form = {
          patient: this.finishedAppointment.patient,
          reason: "",
          date: "",
          location: this.currentLocation?.id,
          estimatedTime: "Sin definir",
        };
        this.$refs.nextAppointmentDateFormRef.dateMenu = true;
      });
    },
    stopAttending() {
      this.$refs.attendingComponent?.changeAttending(null);
    },
    startAttending(appointment) {
      this.$refs.attendingComponent?.changeAttending(appointment);
    },
    handleClickCobrar() {
      this.$emit("click-cobrar", this.appointmentInProgress);
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
