<template>
  <div>
    <v-btn
      small
      depressed
      title="Finalizar consulta con el paciente"
      color="white"
      class="primary--text"
      @click="showWarning()"
    >
      <v-icon small class="mr-1"> mdi-check </v-icon>
      Finalizar consulta
    </v-btn>
    <WarningDialog
      ref="WarningDialogRef"
      @action="nextTurn()"
      :loading="loading"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import WarningDialog from "../WarningDialog.vue";

export default {
  name: "NextTurn",
  props: ["updateAppointmentList"],
  components: {
    WarningDialog,
  },
  data() {
    return {
      loading: false,
      warning: false,
    };
  },
  computed: { ...mapGetters(["todayAppointments", "attending"]) },
  methods: {
    ...mapMutations(["setAlert", "setAppointments"]),
    showWarning() {
      this.$refs.WarningDialogRef.description =
        "¿Seguro que desea finalizar la consulta actual?";
      this.$refs.WarningDialogRef.btnText = "Finalizar";
      this.$refs.WarningDialogRef.color = "dark_primary";
      this.$refs.WarningDialogRef.show = true;
    },
    async nextTurn() {
      try {
        this.loading = true;

        const res = await axios.put(
          "/api/appointment/end/" + this.attending.id,
          {
            appointment: this.attending,
          }
        );

        const { appointments, appointment } = res.data;
        this.$emit("end", appointment);

        if (this.updateAppointmentList) {
          this.updateAppointmentList({
            newAppointmentList: appointments,
            appointment,
          });
        }

        this.$refs.WarningDialogRef.show = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          timeout: 4000,
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
