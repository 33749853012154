<template>
  <v-form v-model="validation" ref="form" @submit.prevent="createAppointment()">
    <v-container class="mb-5">
      <Title :title="'Agendar próxima consulta'" class="mb-8" />
      <v-row>
        <v-col cols="12" md="12" class="d-flex">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Fecha"
                readonly
                outlined
                :value="formattedDate"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date"
              locale="es-ES"
              color="primary"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="appointmentMode === 'Hora estimada'" cols="12" md="6">
          <v-dialog
            ref="dialog"
            v-model="menu"
            :return-value.sync="time"
            width="400px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formattedTime"
                label="Hora estimada"
                readonly
                outlined
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu"
              v-model="time"
              full-width
              ampm-in-title
              color="dark_primary"
            >
              <v-btn text color="dark_primary" @click="menu = false">
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                dark
                depressed
                color="dark_primary"
                @click="$refs.dialog.save(time)"
              >
                Listo
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="form.location"
            :items="locations"
            label="Sede"
            item-value="id"
            item-text="name"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-combobox
            v-model="form.reason"
            :items="[
              'Consulta',
              'Procedimiento',
              'Entrega de resultado o analítica',
              'Vacunación',
              'Chequeo rutinario',
            ]"
            label="Motivo"
            :rules="rules.default"
            outlined
          ></v-combobox>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      small
      depressed
      block
      color="primary"
      class="pa-5"
      type="submit"
      :disabled="!validation"
      :loading="loading"
    >
      Guardar
    </v-btn>
  </v-form>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import Title from "../Title.vue";

export default {
  name: "NextAppointmentDateForm",
  components: {
    Title
  },
  data() {
    return {
      form: {
        patient: null,
        location: "",
        reason: "",
        date: "",
        estimatedTime: "Sin definir",
      },
      time: "",
      menu: false, // time menu
      dateMenu: false, // date menu
      appointmentMode: "Orden de llegada",
      appointmentModes: [
        { text: "Orden de llegada", value: "Orden de llegada" },
        { text: "Hora definida", value: "Hora estimada" },
      ],
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 100) || "Debe tener menos de 100 letras",
        ],
        select: [(v) => !!v || "Campo obligatorio"],
      },
      loading: false,
      validation: true,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentLocation", "locations"]),
    formattedDate() {
      return this.form.date
        ? moment(this.form.date).format("DD/MM/YYYY")
        : moment().format("DD/MM/YYYY");
    },
    formattedTime() {
      if (this.time) return moment(this.time, "HH:mm").format("hh:mm A");
      else return "00:00";
    },
    timeRule() {
      if (this.form.estimatedTime === "Hora estimada") {
        return [(v) => !!v || "Campo obligatorio"];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapMutations(["setAlert", "pushAppointment"]),
    async createAppointment() {
      try {
        this.loading = true;

        this.form.estimatedTime =
          this.appointmentMode === "Hora estimada" ? this.formattedTime : null;

        this.form.doctor = this.currentUser.doctor.id;

        if (!this.form.location) {
          this.form.location = this.currentLocation?.id;
        }

        const res = await axios.post("/api/appointment", this.form);
        const { appointment, message } = res.data;
        const currentDate = moment().format("YYYY-MM-DD");
        if (
          currentDate === appointment.date &&
          appointment?.location?.id === this.currentLocation?.id
        ) {
          this.pushAppointment(appointment);
        }
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.form.reason = "";
        this.$refs.form.resetValidation();
        this.$emit("created", appointment);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
        this.loading = false;
      }
    },
  },
};
</script>
