import { render, staticRenderFns } from "./AppointmentInProgress.vue?vue&type=template&id=4018c460&scoped=true&"
import script from "./AppointmentInProgress.vue?vue&type=script&lang=js&"
export * from "./AppointmentInProgress.vue?vue&type=script&lang=js&"
import style0 from "./AppointmentInProgress.vue?vue&type=style&index=0&id=4018c460&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4018c460",
  null
  
)

export default component.exports