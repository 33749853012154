<template>
  <div>
    <v-btn
      v-if="attending"
      @click="changeAttending(null)"
      small
      text
      :color="cancelarColor ? cancelarColor : 'white'"
      title="Cancelar consulta"
      :loading="loading"
    >
      cancelar
    </v-btn>
    <v-btn
      v-else-if="showStart"
      @click="handleClickStart"
      depressed
      :loading="loading"
    >
      Iniciar consulta
      <v-icon class="ml-2">mdi-account-clock</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "ChangeAttendingStatus",
  props: ["appointment", "clickStart", "cancelarColor", "showStart"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "attending"]),
  },
  methods: {
    ...mapMutations([
      "setAttendingStatus",
      "updateAppointment",
      "setAttendingAppointment",
    ]),
    handleClickStart() {
      if (this.clickStart) {
        this.clickStart();
        return false;
      }

      this.$emit("start-appointment");
    },
    async changeAttending(value) {
      try {
        this.loading = true;

        const res = await axios.put(
          `/api/doctor/attending/${this.currentUser.doctor.id}`,
          {
            appointment: value,
          }
        );
        const { appointment } = res.data;
        this.loading = false;

        if (appointment) {
          this.setAttendingStatus({
            ...value,
            start: appointment.start,
          });
          this.updateAppointment(appointment);
          this.setAttendingAppointment(appointment);
        } else {
          this.setAttendingStatus(null);
        }
        this.$emit("atteding-status-changed", appointment);
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>
