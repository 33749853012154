<template>
  <v-progress-linear
    color="white"
    striped
    :value="progress"
  ></v-progress-linear>
</template>

<script>
export default {
  name: "AutoLinearProgressBar",
  props: ["time"],
  data() {
    return {
      progress: 0,
      stopAt: 100,
      intervalId: null,
    };
  },
  methods: {
    updateProgress() {
      this.progress++;
      if (this.progress === this.stopAt) {
        clearInterval(this.intervalId);
        this.$emit('end');
      }
    },
    start() {
      this.progress = 0;
      const time = this.time || 1000;
      const updateTime = time / 100;
      this.intervalId = setInterval(this.updateProgress, updateTime);
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId); 
  },
};
</script>
